import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { TimeoutComponent } from './timeout/timeout.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SharedService } from './shared-service/shared.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import ApiInterceptor from './api.interceptor';
import { AuthGuard } from './auth-guard/auth-guard.service';
import { DragAndDropDirective } from './directives/dragAndDrop.directive';
import { PipesModule } from './pipes/pipes.module';
import { RouterConstants } from './constants/router-constants';
import { ReportIssuesModule } from './report-issues/report-issues.module';
import { QuillModule } from 'ngx-quill';
import { AgGridModule } from 'ag-grid-angular';
import { DataRefreshRendererComponent } from './data-refresh-renderer/data-refresh-renderer.component';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IdleExpiry, NgIdleModule, SimpleExpiry } from '@ng-idle/core';
import { InfoModule } from './info-details/info-details.module';
import { MentionModule } from 'angular-mentions';
import { CallbackModule } from './callback/callback.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SimpleCellRendererComponent } from './common-renderer/simple-cell-renderer/simple-cell-renderer.component';
import { ActionRendererComponent } from './common-renderer/action-renderer/action-renderer.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { ChatbotModule } from './chatbot/chatbot.module';
import { MarkdownModule } from 'ngx-markdown';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        AppComponent,
        TimeoutComponent,
        LoaderComponent,
        DragAndDropDirective,
        DataRefreshRendererComponent,
        SimpleCellRendererComponent,
        ActionRendererComponent
    ],
    imports: [
        CommonModule,
        RouterOutlet ,
        RouterModule,
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        NgbModule,
        HttpClientModule,
        PipesModule,
        MatIconModule,
        NgIdleKeepaliveModule.forRoot(),
        NgIdleModule.forRoot(),
        QuillModule.forRoot(),
        ReportIssuesModule,
        InfoModule,
        MentionModule,
        CallbackModule,
        OAuthModule.forRoot({
            resourceServer: {
              allowedUrls: [
                'http://localhost:8080',
                'https://scworkbench-dev-service.cloudapps.cisco.com/gsmscw/api'
              ],
              sendAccessToken: true,
            },
          }),
        AgGridModule,
        ChatbotModule,
        MarkdownModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production ,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'}),
    ],
    providers: [
        SharedService,
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        
        {
            provide: IdleExpiry,
            useClass: SimpleExpiry
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        AuthGuard,
        RouterConstants
    ],
    bootstrap: [AppComponent],
    exports: [DragAndDropDirective]
})
export class AppModule { }
