import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { MatDialogModule } from '@angular/material/dialog';
import { ChatbotDialogComponent } from './chatbot/chatbot-dialog.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ChatbotDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MarkdownModule.forChild(),
    MatDialogModule,
  ],
  exports: [ChatbotDialogComponent]
})
export class ChatbotModule {}