import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/shared-service/shared.service';

@Component({
  selector: 'app-chatbot-dialog',
  templateUrl: './chatbot-dialog.component.html',
  styleUrls: ['./chatbot-dialog.component.css']
})
export class ChatbotDialogComponent {
  dataArray: any = [];
  searchPrompt: any = ''
  showLoader: boolean = true;
  disableInput: boolean;
  isCopied: boolean;
  showChatBot: boolean = false;
  inputParams: any = {};
  showCommentModal: boolean;
  currentData: any;
  newComment: any;
  public apiUrl: any;

  constructor(private http: HttpClient,
    private sharedService: SharedService,
     private dialogRef: MatDialogRef<ChatbotDialogComponent>) {
      this.apiUrl = window['__env'].apiUrl;
     }


  ngOnInit() {
    this.sharedService.getClickEvent().subscribe((value: boolean) => {
      this.handleClickEvent(value);
    });

    this.sharedService.getLogOut().subscribe((data: any) => {
      if (data) {
        this.closeDialog();
      }
    });
  }

  handleClickEvent(value: boolean) {
    // Handle the event here
    console.log('Click event received with value:', value);
    this.closeDialog();

  }


  onSend(prompt: any) {
    this.disableInput = true;
    this.showLoader = true;
    let index = this.dataArray.length;
    this.dataArray[index] = { "prompt": prompt }
   
    this.http.post(`${this.apiUrl}/scChatAI/modelAPI`, 
      { question: prompt, role: 'scw_internal' } ).subscribe(
      (data: any) => {
        this.dataArray[index] = { ...this.dataArray[index], "response": data?.response , "run_id": data?.run_id }
        console.log(this.dataArray[index]);
        this.showLoader = false;
        this.disableInput = false;
        this.searchPrompt = '';
      },
      (error) => {
        this.dataArray[index] = { ...this.dataArray[index], "response": 'Sorry, I am not able to process your request at the moment. Please try again later.' }
        this.showLoader = false;
        this.disableInput = false;
        this.searchPrompt = '';
      }
    );
  }

  checkEnterKey(e: any, prompt: any) {
    if (e.keyCode === 13) {
      this.onSend(prompt);
      e.preventDefault();
    }
  }

  closeDialog(): void {
    this.dialogRef.close(); 
  }

  triggerFeedbackApi(data) {
    this.showLoader = true;
    if(data?.comment) {
      this.inputParams.comment = data.comment;
    }
    this.inputParams.feedback_key = data.goodResponse ? "thumbs_up" : data.badResponse ? "thumbs_down" : "";
    this.inputParams.run_id = data.run_id;
    this.http.post(`${this.apiUrl}/scChatAI/feedbackAPI`, 
      this.inputParams).subscribe((data: any) => {

    });
  }

  goodResponse(data: any) {

    data.goodResponse = !data.goodResponse;
    if (data.goodResponse) {
      data.badResponse = false;
    }
    this.triggerFeedbackApi(data);
  }
  
  badResponse(data: any) {
    data.badResponse = !data.badResponse;
    if (data.badResponse) {
      data.goodResponse = false; 
    }
    this.triggerFeedbackApi(data);
  }

  addComment(data: any) {
    this.currentData = data;
    this.showCommentModal = true;
  }

  confirmComment() {
    this.inputParams.comment = this.newComment;
    this.showCommentModal = false;
    this.triggerFeedbackApi(this.currentData);
  }

  copyClipboard(data: any) {
    this.isCopied = true;
    navigator.clipboard.writeText(data);
    setTimeout(() => {
      this.isCopied = false;
    }, 2000)
  }
}