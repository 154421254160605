<div class="chat-bot-container">
  <button class="close-button" (click)="closeDialog()">
    <i class="fa fa-times"></i>
  </button>

  <div class="chat-response-wrapper">
    <div class="chat-response-body">
      <!-- Display when there are no responses yet -->
      <div *ngIf="dataArray.length === 0" class="h-100">
        <div class="empty-response">
          <span class="spriteHome spriteHome-cisco-logo" alt="SC IT"></span>
          <h1 class="help-text">How can I help you?</h1>
        </div>
      </div>

      <!-- Iterate over each item in dataArray -->
      <div *ngFor="let item of dataArray">
        <!-- User input card -->
        <div class="user-card">
          <!-- <div class="user-card-base"> -->
            <div class="user-info">
              <div class="user-card-content">
                <div class="user-card-title">You</div>
              </div>
            </div>
            <div class="user-prompt">
              <p>{{ item.prompt }}</p>
            </div>
          <!-- </div> -->
        </div>

        <!-- Loading indicator for response -->
        <div *ngIf="!item.response">
          <div class="user-info">
            <i class="fa fa-spinner fa-pulse fa-2x fa-fw" style="color: #1990fa"></i>
            <span class="sr-only">Loading...</span>
            <div class="user-card-content">
              <div class="user-card-title">Generating response...</div>
            </div>
          </div>
        </div>

        <!-- Bot response card -->
        <div *ngIf="item.response">
          <div class="user-info">
            <!-- <div class="user-card-img">
              <img src="./assets/images/Cisco-logo-dark.png" alt="SC IT" class="cisco-logo">
            </div> -->
            <div class="user-card-content">
              <div class="user-card-title">SC IT</div>
            </div>
          </div>
          <div class="bot-response">
            <markdown [data]="item.response"></markdown>
            <div class="feedback-icons">
              <i class="far fa-clone" (click)="copyClipboard(item.response)"></i>
              <i [ngClass]="item.goodResponse ? 'fas fa-thumbs-up' : 'far fa-thumbs-up'" (click)="goodResponse(item)"></i>
              <i [ngClass]="item.badResponse ? 'fas fa-thumbs-down' : 'far fa-thumbs-down'" (click)="badResponse(item)"></i>
              <i class="far fa-comment" (click)="addComment(item)"></i> 
            </div>
          </div>
        </div>
        

        
      </div>
    </div>
  </div>

  <!-- Input area -->
  <div class="textarea-block">
    <div class="chat-bot-input-bar">
      <textarea
        [disabled]="disableInput"
        #chatBotInput
        [(ngModel)]="searchPrompt"
        placeholder="How can I help you today?"
        rows="1"
        class="chatbot-textarea"
        (keydown)="checkEnterKey($event, searchPrompt)"
      ></textarea>
      <button
        class="send-button"
        [ngStyle]="{
          'pointer-events': searchPrompt === '' || disableInput ? 'none' : '',
          opacity: searchPrompt === '' || disableInput ? '0.5' : '1'
        }"
        (click)="onSend(searchPrompt)"
      >
        <i class="fa fa-paper-plane"></i>
      </button>
    </div>
  </div>



</div>


  <!-- Comment Modal -->
  <div *ngIf="showCommentModal" class="comment-modal">
    <div class="modal-content">
      <h4><strong>Add Comment</strong></h4>
      <input [(ngModel)]="newComment" placeholder="Add comment" class="rename-input" />
      <div class="button-group">
        <button class="btn btnSmall btnPrimary" (click)="confirmComment()">Save</button>
        <button class="btn btnSmall btnSmallDefault" (click)="showCommentModal=false">Cancel</button>
      </div>
    </div>
  </div>
